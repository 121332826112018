import Seo from './Seo';

// import { TWallpaper } from '@twallpaper/react'
// import '@twallpaper/react/css'
// import { bgColors } from '@/constants';
// import { useThemeParams } from '@vkruglikov/react-telegram-web-app';

export default function Page({ title, description, meta, children }) {
	// const [colorScheme] = useThemeParams();
	// let mask = colorScheme === 'dark'
	// let colors = mask ? bgColors.amethyst : bgColors.default

	return (
		<>
			<Seo
				title={title}
				description={description}
				meta={meta ? meta : []}
			/>

			{/* <TWallpaper options={{
				colors: ['#f4f4f5', '#f4f4f5', '#f4f4f5', '#f4f4f5'],
				pattern: {
					image: process.env.PUBLIC_URL + '/img/patterns/cats_and_dogs.svg',
					mask: mask
				}
			}} /> */}

			<main>
				{children}
			</main>
		</>
  	);
}

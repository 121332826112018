import React from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { store } from './store';
import { HelmetProvider } from 'react-helmet-async';
import { WebAppProvider } from '@vkruglikov/react-telegram-web-app';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorComponent from './components/ErrorComponent';

export default function Providers({children}) {
  	return (
		<StoreProvider store={store}>
			<WebAppProvider>
				<HelmetProvider>
					<ErrorBoundary FallbackComponent={ErrorComponent}>
						{children}
					</ErrorBoundary>
				</HelmetProvider>
			</WebAppProvider>
		</StoreProvider>
  	);
}

import axios from 'axios'

const api = () => {
	axios.defaults.baseURL = process.env.REACT_APP_API_PROTOCOL + '://' + process.env.REACT_APP_API_HOST

	axios.interceptors.request.use(
		(config) => {
			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	axios.interceptors.response.use(function (response) {
		return response
	}, function (error) {
		return Promise.reject(error)
	});

	return axios
}
export default api
